/* eslint-disable no-unused-expressions */
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Axios from '../../Axios';
// import { Add_Procedure_Category } from '../../../graphQl/medicalProcedures/medicalProceduresQuery&mutation';
// import "../../patient/PatientsWrapper.scss";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import User from "./User.js";

export default function UsersTable(props) {
  const [users, setUsers] = React.useState(props.users)
  const [name, setName] = React.useState()
  const [email, setEmail] = React.useState()
  const [password, setPassword] = React.useState()
  const [showPassword, setShowPassword] = React.useState(false)
  const [showAdd, setShowAdd] = React.useState(false)
  // React.useEffect(() => {
  //   if (props.categories) {
  //     setCategories(props.categories)
  //   }
  // },[props.categories])
  const onChanged = (data, type) => {
    props.onChanged(data, type);
  };
  const addUser = () => {
    if (name && email) {
        let user = new FormData()
        name ? user.append('name', name) : ''
        email ? user.append('email', email) : ''
        password ? user.append('password', password) : ''
        user.append('is_admin', 0)
        Axios.post(`users`, user)
        .then(res => {
            onChanged(res.data.message, 'success')
        })
        .catch(error => {
            onChanged(error.response.data.message, 'error')
        })
    }
  }
  return (
    <TableContainer className="patients-table-container">
      <Table
        stickyHeader
        sx={{ minWidth: 500 }}
        size="small"
        className="patients-table"
      >
        <TableHead className="patients-table-head relative h-10">
          <TableRow className="patients-table-head-row">
            <TableCell>{<FormattedMessage id="app.labels.name"/>}</TableCell>
            <TableCell>{<FormattedMessage id="app.labels.email"/>}</TableCell>
            <TableCell>{<FormattedMessage id="app.labels.password"/>}</TableCell>
            
            <TableCell align="right">
              <Button variant="contained" className="mx-2 absolute right-20 bottom-0 whitespace-nowrap" onClick={() => setShowAdd(true)}>{<FormattedMessage id="app.labels.newUser"/>}</Button>
              {<FormattedMessage id="app.labels.patientsActions"/>}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="patients-table-body">
         {showAdd ?  <TableRow
              className="patients-table-body-row"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell scope="row">
                <div className="procedures-category">
                  <Box className="input-box">
                      <TextField
                          value={name}
                          id="name"
                          name="name"
                          variant="outlined"
                          color="secondary"
                          onChange={(e) => setName(e.target.value)}
                          className="title-field"
                      />
                  </Box>
                </div>
              </TableCell>
              <TableCell scope="row">
                <div className="procedures-category">
                  <Box className="input-box">
                      <TextField
                          value={email}
                          id="email"
                          name="email"
                          variant="outlined"
                          color="secondary"
                          onChange={(e) => setEmail(e.target.value)}
                          className="title-field"
                      />
                  </Box>
                </div>
              </TableCell>
              <TableCell scope="row">
                <div className="procedures-category">
                  <Box className="input-box">
                    <TextField
                      //  label="Password"
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => setPassword(e.target.value)}
                        color="secondary"
                        InputProps={{ 
                        endAdornment: <InputAdornment className="end-clinic-adornment" position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="end-clinic-adornment justify-end"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                            position="end"
                                        >
                                            {showPassword ? <Visibility className="fill-current text-secondary"/> : <VisibilityOff className="fill-current text-secondary"/>}
                                        </IconButton>
                                     </InputAdornment>,}}
                    />
                </Box>
                </div>
              </TableCell>
                  
              <TableCell align="right">
                <div className="flex items-center justify-end gap-2">
                  <Button variant="contained" disabled={!name || !email || !password} onClick={addUser}>add</Button>
                  <Button variant="outlined" onClick={() => {
                    setShowAdd(false)
                    setName()
                    setEmail()
                    setPassword()
                  }}>cancel</Button>
                </div>
              </TableCell>
              
            </TableRow> : ''}
          {users.length? users.map((user) => (
            <>
            <User key={user.id} user={user} onChanged={onChanged} /> 
            </>
          )) : 
          ''}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
