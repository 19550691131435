import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Button from '@mui/material/Button';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, NavLink, useLocation } from 'react-router-dom';
// import logo from '../../assets/images/logo.png';
import useToken from '../../useToken';
import useUser from '../../useUser';
import LogOutButton from '../auth/LogOutButton';
import LocaleSwitcher from '../Locale/LocaleSwitcher';
import './MainNavBar.scss';
function MainNavBar(props) {     
    const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
    
    const { user } = useUser();
    const { token } = useToken();
    const locale = JSON.parse(localStorage.getItem('locale')) || 'en'
    const location = useLocation()
    const openSideMenu = () => {
        setSideMenuOpen(!sideMenuOpen)
    }
    return(
        <div className={`main-layout-wrapper`}>  
            <nav className="nav">
                <div className="nav-wrapper">
                    <div className="flex items-center">
                        {/* <div onClick={openSideMenu} className="menu-icon block xs:hidden">  
                            { sideMenuOpen ? <CloseOutlinedIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
                        </div> */}
                        {/* <figure className="logo-wrapper">
                            <Link to="/"><img src={logo} alt="logo" className="logo"/></Link>
                        </figure> */}
                        <Link to="/">
                            <div className="logo-wrapper flex justify-center items-center border">
                                <div>
                                    Logo
                                </div>
                            </div>
                        </Link>
                    </div>
                    {token && <div className='w-4/6 px-4'>
                        <ul className='flex'>
                            {/* <li> */}
                                {false && <NavLink to="/" >
                                    <li className="nav-menu-item">
                                        <HomeOutlinedIcon className="nav-menu-item-icon" />
                                        <p className="nav-menu-item-text">{<FormattedMessage id="app.texts.home"/>}</p>
                                    </li>
                                </NavLink>}
                                <NavLink to="/products">
                                    <li className="nav-menu-item">
                                        <Inventory2OutlinedIcon className="nav-menu-item-icon" />
                                        <p className="nav-menu-item-text">{<FormattedMessage id="app.texts.products"/>}</p>
                                    </li>
                                </NavLink>
                                <NavLink to="/notification-channels">
                                    <li className="nav-menu-item">
                                        <NotificationsActiveOutlinedIcon className="nav-menu-item-icon" />
                                        <p className="nav-menu-item-text">{<FormattedMessage id="app.labels.notificationChannels"/>}</p>
                                    </li>
                                </NavLink>
                                <NavLink to="/categories">
                                    <li className="nav-menu-item">
                                        <CategoryOutlinedIcon className="nav-menu-item-icon" />
                                        <p className="nav-menu-item-text">{<FormattedMessage id="app.labels.categories"/>}</p>
                                    </li>
                                </NavLink>
                                <NavLink to="/users">
                                    <li className="nav-menu-item">
                                        <PeopleAltOutlinedIcon className="nav-menu-item-icon" />
                                        <p className="nav-menu-item-text">{<FormattedMessage id="app.labels.users"/>}</p>
                                    </li>
                                </NavLink>
                            {/* </li> */}
                        </ul>
                    </div>}
                    <div className="actions-wrapper">
                        <LocaleSwitcher />
                            <span className="mx-1 text-center">{user?.name}</span>
                            {token ? <LogOutButton />: <Link to="/login"><Button className="button" variant="contained">{<FormattedMessage id="app.labels.Login"/>}</Button></Link>}
                        
                    </div>
                </div>
            </nav>
            <div className="page-wrapper">
                {/* <aside className={`side-menu ${sideMenuOpen ? 'is-side-menu-open' : ''}`}>
                    <div className="side-menu-wrapper">
                        <ul className="side-menu-items">
                            <Link to="/">
                                <li className="side-menu-item">
                                    <HomeOutlinedIcon className="side-menu-item-icon" />
                                    <p className="side-menu-item-text">{<FormattedMessage id="app.texts.home"/>}</p>
                                </li>
                            </Link>
                        </ul>
                    </div>
                </aside> */}
                <div className="content-wrapper overflow-x-hidden">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default MainNavBar;