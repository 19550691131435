import { createTheme, ThemeProvider } from '@mui/material/styles';

const locale = JSON.parse(localStorage.getItem('locale')) || 'en';
const font = locale === 'ar' ? "'cairo', sans-serif" : "'Montserrat', sans-serif";
// const font = locale === 'ar' ? "'Swissra', sans-serif" : "'Montserrat', sans-serif";
const direction = locale === 'ar' ? 'ltr' : 'ltr'
const theme = createTheme({
  direction: direction,
  breakpoints: {
    values: {
      '2xs': 375,
      xs: 550,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536
    },
  },
  palette: {
    primary: { 
      main: '#27BDBE',
      // main: '#009688',
    },
    secondary: { 
      // main: '#27BDBE',
      main: '#00695F',
    },
    third: {
      main: '#33AB9F',
    },
    gray: {
      main: '#F1F1F1',
    }
  },
  typography: {
    fontFamily: font,
    header: {
      fontSize: '3rem',
    },
    subHeader: {
      fontSize: '2rem',
    },
  },
});

export default function FirstTheme(props) {
    return(
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    );
}