import { createSlice } from '@reduxjs/toolkit'

export const patientTabSlice = createSlice({
  name: 'patientTab',
  initialState: {
    tabValue: 'all'
  },
  reducers: {
    setPatientTab: (state, action) => {
      state.tabValue = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setPatientTab } = patientTabSlice.actions

export default patientTabSlice.reducer