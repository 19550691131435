import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import MainNavBar from "./components/layout/MainNavBar.js";
import Login from "./pages/auth/Login.js";
import Categories from './pages/categories/Categories';
import Home from "./pages/Home.js";
import NotFound from "./pages/notFound/NotFound";
import NotificationsChannel from './pages/notificationsChannel/NotificationsChannel';
import Products from './pages/products/Products';
import Users from './pages/users/Users';
import FirstTheme from "./themes/FirstTheme";
import useToken from "./useToken";
import useUser from "./useUser";

const locale = JSON.parse(localStorage.getItem("locale")) || "en";

function App() {
  const { token, setToken } = useToken();
  const { user } = useUser()
  
  function RequireAuth({ children }) {
    const location = useLocation();
    return token ? (
      children
    ) : (
      <Navigate to="/login" replace state={{ path: location.pathname }} />
    );
  }
  function Authed({ children }) {
    return token ? <Navigate to="/" replace /> : children;
  }
  // function RedirectPath({ children }) {
  //   const location = useLocation();
  //   return location.pathname === '/' ? <Navigate to="/" replace /> : ''
  // }
  return (
    <FirstTheme>
      <div
        dir={locale === "ar" ? "ltr" : "ltr"}
        className={`${locale === "ar" ? "is-rtl" : ""} wrapper`}
      >
        <Router>
          <MainNavBar>
            <Routes>
              <Route path="/" element={<RequireAuth><Home /></RequireAuth>}></Route>
              
              <Route path="/login" element={<Authed><Login setToken={setToken} /></Authed>}></Route>
              
              <Route path="/products" element={<RequireAuth><Products /></RequireAuth>}></Route>
              <Route path="/notification-channels" element={<RequireAuth><NotificationsChannel /></RequireAuth>}></Route>
              <Route path="/categories" element={<RequireAuth><Categories /></RequireAuth>}></Route>
              <Route path="/users" element={<RequireAuth><Users /></RequireAuth>}></Route>
              
              <Route path="*" element={<NotFound />}></Route>
              
            </Routes>
          </MainNavBar>
        </Router>
      </div>
    </FirstTheme>
  );
}

export default App;
