import { createSlice } from '@reduxjs/toolkit'

export const patientIdSlice = createSlice({
  name: 'patientId',
  initialState: {
    patientObject: {
      id: '',
      name: ''
    }
  },
  reducers: {
    addPatientId: (state, action) => {
      state.patientObject = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { addPatientId } = patientIdSlice.actions

export default patientIdSlice.reducer