import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'))
const locale = JSON.parse(localStorage.getItem('locale'))
const api = axios.create({
    baseURL: `https://api.scrapper.elmwafer.com/api/`,
    // baseURL: `https://api.scrapper.hvacrtoolbox.com/api/`,
    // baseURL: `http://38.242.217.173:8080/api/`,
    // baseURL: `https://38.242.217.173:8080/api`,
    headers: {
      "authorization": token ? `Bearer ${token}` : "",
      "X-Locale": locale ? locale : "en",
    }
});

api.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401 || error.response.message === 'Unauthenticated.') {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    window.location.reload()
  }
  throw error;
});

export default api;