/* eslint-disable no-unused-expressions */
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from '@mui/material';
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import * as React from 'react';
import Axios from '../../Axios';
import Deleter from '../helper/Deleter';
export default function User(props) {
    const [user, setUser] = React.useState(props.user)
    const [name, setName] = React.useState(props.user.name)
    const [email, setEmail] = React.useState(props.user.email)
    const [password, setPassword] = React.useState()
    const [showPassword, setShowPassword] = React.useState(false)
    const basicName = props.user.name
    const basicEmail = props.user.email
    const sendEditRequest = () => {
        if(name && email) {
            let userData = new FormData()
            userData.append('_method', 'PUT')
            name ? userData.append('name', name) : ''
            email ? userData.append('email', email) : ''
            password ? userData.append('password', password) : ''
            userData.append('is_admin', true)
            Axios.post(`users/${props.user.id}`, userData)
            .then(res => {
                onChanged(res.data.message, 'success')
            })
            .catch(error => {
                onChanged(error.response.data.message, 'error')
            })
        }
    }
    
    const onChanged = (data, type) => {
        props.onChanged(data, type);
    };
    return(
        <TableRow
              className="patients-table-body-row"
              key={user.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell scope="row">
                   <section className="procedures-category">
                        <Box className="input-box">
                            <TextField
                                value={name}
                                id="name"
                                name="name"
                                variant="outlined"
                                color="secondary"
                                onChange={(e) => setName(e.target.value)}
                                error={!name}
                                helperText={!name ? 'name is required' : ''}
                                className="title-field"
                            />
                        </Box>
                    </section> 
              </TableCell>
              <TableCell scope="row">
                   <section className="procedures-category">
                        <Box className="input-box">
                            <TextField
                                value={email}
                                id="email"
                                name="email"
                                variant="outlined"
                                color="secondary"
                                onChange={(e) => setEmail(e.target.value)}
                                error={!email}
                                helperText={!email ? 'email is required' : ''}
                                className="title-field"
                            />
                        </Box>
                    </section> 
              </TableCell>
              <TableCell scope="row">
                   <section className="procedures-category">
                        <Box className="input-box">
                    <TextField
                      //  label="Password"
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => setPassword(e.target.value)}
                        color="secondary"
                        InputProps={{ endAdornment: 
                            <InputAdornment className="end-clinic-adornment" position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    className="end-clinic-adornment justify-end"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    position="end"
                                >
                                    {showPassword ? <Visibility className="fill-current text-secondary"/> : <VisibilityOff className="fill-current text-secondary"/>}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                </Box>
                    </section> 
              </TableCell>
              <TableCell align="right">
                <div className="flex items-center justify-end gap-1">
                    <Button variant='contained' disabled={!name || !email } onClick={sendEditRequest}>
                    {/* <Button variant='contained' disabled={(name === basicName && email === basicEmail)} onClick={sendEditRequest}> */}
                        <EditOutlinedIcon
                            className="cursor-pointer"
                        />
                    </Button>
                    <Deleter route="users" id={user.id} title='Delete User' description={`are you sure you want delete ${user.name}`} onDeleted={onChanged}/>
                </div>
              </TableCell>
            </TableRow>
        
    )
}