/* eslint-disable no-unused-expressions */
import { yupResolver } from '@hookform/resolvers/yup';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from "react-intl";
import * as Yup from 'yup';
import Axios from '../../Axios';
import './ProductForm.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 2,
};

export default function EditProduct(props) {
    const [providerErrorMessage, setProviderErrorMessage] = React.useState();
    const [categoryErrorMessage, setCategoryErrorMessage] = React.useState();
    const [scanPeriodErrorMessage, setScanPeriodErrorMessage] = React.useState();
    const [channelErrorMessage, setChannelErrorMessage] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [selectedChannels, setSelectedChannels] = React.useState(props.product.notification_channels.length ? props.product.notification_channels : []);
    const [provider, setProvider] = React.useState(props.product.provider);
    const [category, setCategory] = React.useState(props.product.category);
    const [scanPeriod, setScanPeriod] = React.useState(props.product.scan_period);
    const [available, setAvailable] = React.useState(props.product.is_available)
    const [sellerRequired, setSellerRequired] = React.useState(props.product.is_seller_required)
    const [loading, setLoading] = React.useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        reset()
        setSelectedChannels([])
        setProviderErrorMessage()
        setCategoryErrorMessage()
        setScanPeriodErrorMessage()
        setChannelErrorMessage()
    };
    const validationSchema = Yup.object().shape({
        title: Yup.string(),
        url: Yup.string()
        .required('Please enter URL').url('Enter correct url!'),
        image: Yup.string(),
        price: Yup.string(),
        targetPrice: Yup.string().required('Please enter target price'),
        sellerName: Yup.string(),
        provider: Yup.string(),
        category: Yup.string(),
        scanPeriod: Yup.string(),
        channel: Yup.string(),
    });
    const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            title: props.product.title? props.product.title : '', 
            url: props.product.url? props.product.url : '', 
            image: props.product.image? props.product.image : '', 
            price: props.product.price? props.product.price : '', 
            targetPrice: props.product.target_price? props.product.target_price : '', 
            sellerName: props.product.seller_name? props.product.seller_name : '', 
        }
    });
    const onSubmit = (data) => {
        let errors = 0
        if (!provider) {
            setProviderErrorMessage('field required')
            errors += 1
        }
        if (!category) {
            setCategoryErrorMessage('field required')
            errors += 1
        }
        if (!scanPeriod) {
            setScanPeriodErrorMessage('field required')
            errors += 1
        }
        if (!selectedChannels.length) {
            setChannelErrorMessage('field required')
            errors += 1
        }
        if (!errors) {
            let dataObj = createMedicalReportData(data)
            setLoading(true)
            Axios.post(`products/${props.product.id}`, dataObj)
            .then(res => {
                setLoading(false)
                props.onUpdated(res.data.message, 'success')
                handleClose()
            })
            .catch(error => {
                setLoading(false)
                // re send request
                Axios.post(`products/${props.product.id}`, dataObj)
                .then(res => {
                    setLoading(false)
                    props.onUpdated(res.data.message, 'success')
                    handleClose()
                })
                .catch(error => {
                    props.onUpdated(error.response.data.message, 'error')
                })
            })
        }
        
    }
    const createMedicalReportData = (data) => {
        let productData = new FormData()
        // let providerId = provider && props.providers.filter(provider => provider.name === provider)[0].id
        // let scanPeriodId = scanPeriod && props.scanPeriods.filter(scanPeriod => scanPeriod.name === scanPeriod)[0].id
        let selectedChannelsIds =  []
        selectedChannels.length ? selectedChannelsIds = selectedChannels.map(item => {return item.id}) : selectedChannelsIds = ''
        productData.append('_method', 'PUT')
        data.title ? productData.append('title', data.title) : ''
        data.url ? productData.append('url', data.url) : ''
        data.price ? productData.append('price', data.price) : ''
        data.targetPrice ? productData.append('target_price', data.targetPrice) : ''
        data.sellerName ? productData.append('seller_name', data.sellerName) : ''
        provider ? productData.append('provider_id', provider.id) : ''
        category ? productData.append('category_id', category.id) : ''
        scanPeriod ? productData.append('scan_period_id', scanPeriod.id) : ''
        // selectedChannelsIds.length ? productData.append('notification_channel_ids', JSON.stringify(selectedChannelsIds)) : "";
        // productData.append('is_available', available)
        sellerRequired ? productData.append('is_seller_required', 1) : productData.append('is_seller_required', 0)
        for (var i = 0; i < selectedChannelsIds.length; i++) {
            productData.append(`notification_channel_ids[${i}]`, selectedChannelsIds[i]);
        }
        // data.image ? productData.append('image', data.image) : ''
        return productData
    }
    // React.useEffect(() => {
    //     setTimeout(() => {
    //         console.log('now we in sit time out')
    //         setSelectedChannels(props.product.notification_channels)
    //     }, 2000);
    // }, [])
    return(
        <section className="inline-block">
            <EditOutlinedIcon className='cursor-pointer' onClick={handleOpen} />
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style} className="product-form">
                    <h3 className="text-center text-xl font-bold mb-2">{<FormattedMessage id="app.labels.editProduct" />}</h3>
                    <Box className="inputs-container">
                        <Box className="input-box">
                            <TextField 
                                label={<FormattedMessage id="app.labels.title"/>}
                                id="title"
                                name="title"
                                {...register('title')}
                                error={errors.title ? true : false}
                                variant="outlined" 
                                color="secondary"
                            />
                            <Typography variant="inherit" color="error">
                                {errors.title?.message}
                            </Typography>
                        </Box>
                        <Box className="input-box">
                            <TextField 
                             label={<FormattedMessage id="app.labels.url"/>}
                                id="url"
                                name="url"
                                {...register('url')}
                                error={errors.url ? true : false}
                                variant="outlined" 
                                color="secondary"
                            />
                            <Typography variant="inherit" color="error">
                                {errors.url?.message}
                            </Typography>
                        </Box>
                        {false && <Box className="input-box">
                            <TextField 
                             label={<FormattedMessage id="app.labels.image"/>}
                                id="image"
                                name="image"
                                {...register('image')}
                                error={errors.image ? true : false}
                                variant="outlined" 
                                color="secondary"
                            />
                            <Typography variant="inherit" color="error">
                                {errors.image?.message}
                            </Typography>
                        </Box>}
                        <Box className="input-box">
                            <TextField 
                                label={<FormattedMessage id="app.labels.price"/>}
                                id="price"
                                name="price"
                                {...register('price')}
                                error={errors.price ? true : false}
                                variant="outlined" 
                                color="secondary"
                                type="number"
                            />
                            <Typography variant="inherit" color="error">
                                {errors.price?.message}
                            </Typography>
                        </Box>
                        <Box className="input-box">
                            <TextField 
                                label={<FormattedMessage id="app.labels.targetPrice"/>}
                                id="targetPrice"
                                name="targetPrice"
                                {...register('targetPrice')}
                                error={errors.targetPrice ? true : false}
                                variant="outlined" 
                                color="secondary"
                                type="number"
                            />
                            <Typography variant="inherit" color="error">
                                {errors.targetPrice?.message}
                            </Typography>
                        </Box>
                        <Box className="input-box">
                            <TextField 
                             label={<FormattedMessage id="app.labels.sellerName"/>}
                                id="sellerName"
                                name="sellerName"
                                {...register('sellerName')}
                                error={errors.sellerName ? true : false}
                                variant="outlined" 
                                color="secondary"
                            />
                            <Typography variant="inherit" color="error">
                                {errors.sellerName?.message}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="inputs-container">
                        <Box className="input-box">
                            <Autocomplete
                                value={scanPeriod}
                                onChange={(event, newValue) => {
                                    setScanPeriod(newValue);
                                }}
                                className="uploader-autocomplete"
                                options={props.scanPeriods}
                                openOnFocus={true}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} 
                                label={<FormattedMessage id="app.labels.scanPeriods"/>}/>}
                            />
                            <Typography variant="inherit" color="error">
                                {scanPeriodErrorMessage}
                            </Typography>
                        </Box>
                        <Box className="input-box">
                            <Autocomplete
                                value={provider}
                                onChange={(event, newValue) => {
                                    setProvider(newValue);
                                }}
                                className="uploader-autocomplete"
                                options={props.providers}
                                openOnFocus={true}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} 
                                label={<FormattedMessage id="app.labels.provider"/>}
                                />}
                            />
                            <Typography variant="inherit" color="error">
                                {providerErrorMessage}
                            </Typography>
                        </Box>
                        <Box className="input-box">
                            <Autocomplete
                                value={category}
                                onChange={(event, newValue) => {
                                    setCategory(newValue);
                                }}
                                className="uploader-autocomplete"
                                options={props.categories}
                                openOnFocus={true}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} 
                                label={<FormattedMessage id="app.labels.categories"/>}
                                />}
                            />
                            <Typography variant="inherit" color="error">
                                {categoryErrorMessage}
                            </Typography>
                        </Box>
                        <Box className="input-box">
                            <Autocomplete
                                value={selectedChannels}
                                onChange={(event, newValue) => {
                                    let idsArr = []
                                    newValue.map(item => {
                                        idsArr.push(item)
                                    })
                                    const unique = [...new Map(idsArr.map(item => [item['id'], item])).values()]
                                    setSelectedChannels(unique);
                                }}
                                getOptionSelected={(option, value) => option.id === value.id}
                                multiple
                                className="uploader-autocomplete"
                                disableCloseOnSelect
                                options={props.notificationChannels}
                                openOnFocus={true}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: 300 }}
                                // renderOption={(props, option, { selected }) => (
                                //     <li {...props}>
                                //     <Checkbox
                                //         icon={icon}
                                //         checkedIcon={checkedIcon}
                                //         style={{ marginRight: 8 }}
                                //         checked={selected}
                                //     />
                                //         {option.name}
                                //     </li>
                                // )}
                                renderInput={(params) => <TextField {...params} {...register('channel')} 
                                label={<FormattedMessage id="app.labels.notificationChannels"/>}/>}
                            />
                            <Typography variant="inherit" color="error">
                                {channelErrorMessage}
                            </Typography>
                        </Box>
                    </Box>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={sellerRequired} onChange={(e) => setSellerRequired(e.target.checked)} />} label={<FormattedMessage id="app.labels.sellerRequired"/>} />
                    </FormGroup>
                    <div className="flex">   
                        <Button  className="ml-auto" onClick={handleClose}>{<FormattedMessage id="app.buttons.cancel"/>}</Button>
                        <Button disabled={loading} onClick={handleSubmit(onSubmit)}>{<FormattedMessage id="app.buttons.save"/>}</Button>
                    </div>
                </Box>
            </Modal>
        </section>
    )
}