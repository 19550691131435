import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from '../useToken';

function Home() {
   const navigate = useNavigate()
   const {token} = useToken()
   React.useEffect(() => {
      if (token) {
         navigate('/products')
      } else {
         navigate('/login')
      }
   }, [])
 return( 
     <section>
        home page
     </section> 
  );
}


export default Home;