/*************************dev*****************************/
// export const baseUrl = 'https://api.dev.tabibclick.com/'
export const baseUrl = 'https://dev.api.tabibclick.com/'
// export const baseUrl = 'http://localhost:8000/'
// export const baseUrl = 'https://dev.api.tabibclick.net/'


/*************************test*****************************/
// export const baseUrl = 'https://api.test.tabibclick.com/'
// export const baseUrl = 'https://test.api.tabibclick.com/'


/*************************demo*****************************/
// export const baseUrl = 'https://api.demo.tabibclick.com/'
// export const baseUrl = 'https://demo.api.tabibclick.com/'
// export const baseUrl = 'https://demo.api.tabibclick.net/'

/*************************app*****************************/
// export const baseUrl = 'https://api.app.tabibclick.com/'
// export const baseUrl = 'https://app.api.tabibclick.com/'