import { gql } from '@apollo/client';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from "react-router-dom";
import Axios from '../../Axios';
import CustomSnackBar from '../ui/CustomSnackBar';

const LOGOUT_USER = gql`
  mutation Logout {
         logout
        }
`;

export default function LogOutButton() {
    const [successMessage, setSuccessMessage] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const navigate = useNavigate();
    // const [sendLogoutRequest] = useMutation(LOGOUT_USER, {
    //     onCompleted: (data) => {
    //         localStorage.removeItem('token')
    //         localStorage.removeItem('user')
    //         navigate("/login");
    //         window.location.reload()
    //         setSuccessMessage(`successes : ${data.logout}`);
    //     },
    //     onError: (error) => {
    //         setErrorMessage(error);
    //     }
    // });
    
    const sendLogoutRequest = () => {
        Axios.post(`logout`)
        .then(res => {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            navigate("/login");
            window.location.reload()
            setSuccessMessage(`successes : ${res.data.message}`);
        })
        .catch(error => {
            setErrorMessage(error.response.data.message);
        })
    }
    
    function handleLogOut() {
        setErrorMessage()
        setSuccessMessage()
        sendLogoutRequest()
    }
    return (
        <section>
            <Button className="button whitespace-nowrap" variant="contained" onClick={handleLogOut} >{<FormattedMessage id="app.labels.LogOut" />}</Button>
            { successMessage && <CustomSnackBar open={true} message={`${successMessage}`} severity="success"/> }
            { errorMessage && <CustomSnackBar open={true} message={`${errorMessage}`} severity="error"/> }
        </section>
    )
}