import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Axios from '../../Axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
};

export default function Deleter(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    setLoading(true)
    Axios.delete(`${props.route}/${props.id}`)
    .then(res => {
        setLoading(false)
        handleClose()
        onDeleted(res.data.message, 'success')
      })
      .catch(error => {
        setLoading(false)
        console.log(error.response)
        onDeleted(error.response.data.message, 'error')
      })
  }
  const onDeleted = (data, type) => {
    props.onDeleted(data, type)
  }
  

  return (
    <>
      {props.button ? <Button variant='contained' onClick={handleOpen}><FormattedMessage id="app.buttons.delete"/></Button> : <DeleteOutlineOutlinedIcon className="cursor-pointer" onClick={handleOpen} />}
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {props.description}
          </Typography>
          <div className="flex">
        
          <Button className="ml-auto" onClick={handleClose}>{<FormattedMessage id="app.buttons.cancel"/>}</Button>
          <Button onClick={handleDelete} disabled={loading}>{<FormattedMessage id="app.buttons.delete"/>}</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
