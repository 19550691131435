/* eslint-disable no-unused-expressions */
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Button } from '@mui/material';
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import * as React from 'react';
import Axios from '../../Axios';
import Deleter from '../helper/Deleter';
export default function Category(props) {
    const [category, setCategory] = React.useState(props.category)
    const [name, setName] = React.useState(props.category.name)
    const basicName = props.category.name
    const sendEditRequest = () => {
        if(name) {
            let categoryData = new FormData()
            categoryData.append('_method', 'PUT')
            name ? categoryData.append('name', name) : ''
            Axios.post(`categories/${props.category.id}`, categoryData)
            .then(res => {
                onChanged(res.data.message, 'success')
            })
            .catch(error => {
                onChanged(error.response.data.message, 'error')
            })
        }
    }
    
    const onChanged = (data, type) => {
        props.onChanged(data, type);
    };
    return(
        <TableRow
              className="patients-table-body-row"
              key={category.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell scope="row">
                   <section className="procedures-category">
                        <div className=''>
                            <div className="">
                                <div className="">
                                    <Box className="input-box">
                                        <TextField
                                            value={name}
                                            id="name"
                                            name="name"
                                            variant="outlined"
                                            color="secondary"
                                            onChange={(e) => setName(e.target.value)}
                                            error={!name}
                                            helperText={!name ? 'name is required' : ''}
                                            className="title-field"
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </section> 
              </TableCell>
              <TableCell align="right">
                <div className="flex items-center justify-end gap-1">
                    <Button variant='contained' disabled={(name === basicName) || !name} onClick={sendEditRequest}>
                        <EditOutlinedIcon
                            className="cursor-pointer"
                        />
                    </Button>
                    <Deleter route="categories" id={category.id} title='Delete Category' description={`are you sure you want delete ${category.name}`} onDeleted={onChanged}/>
                </div>
              </TableCell>
            </TableRow>
        
    )
}