import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';


const CustomTextField = styled(TextField)({
  '& label': {
    color: '#009688',
    visibility: "visible",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#009688',
    },
    '&:hover fieldset': {
      borderColor: '#009688',
    },
  },
});

export default CustomTextField;