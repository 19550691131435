import { createSlice } from "@reduxjs/toolkit";

export const clinicSlice = createSlice({
  name: "clinic",
  initialState: [
    
  ],
  reducers: {
    getAllClinicsAction: (state, action) => {
      if(state.length===0){
         //console.log("first get")
       state.push(action.payload.clinic);
    }
      else{
       // console.log("scond get")
        let index = state.findIndex((clinic) => clinic.id === action.payload.id);
        state[index] = action.payload;
      }
     
      // 
    },
    getClinicAction: (state, action) => {
      let Index = state.findIndex((clinic) => clinic.id === action.payload);
    },
    editClinicAction: (state, action) => {
      let index = state.findIndex((clinic) => clinic.id === action.payload.id);
      //console.log(index);
     // console.log("from edit action state[index] ",state[index],"paylod",action.payload);
       state[index] = action.payload;
      // console.log("from edit action  ", state, action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { editClinicAction, getClinicAction, getAllClinicsAction } =
  clinicSlice.actions;

export default clinicSlice.reducer;
