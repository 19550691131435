/* eslint-disable no-unused-expressions */
import { yupResolver } from '@hookform/resolvers/yup';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from "react-intl";
import * as Yup from 'yup';
import Axios from '../../Axios';
import CustomSnackBar from "../ui/CustomSnackBar";
import './ProductForm.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 2,
};

export default function NewProduct(props) {
    const token = JSON.parse(localStorage.getItem('token'))
    const [successMessage, setSuccessMessage] = React.useState();
    const [errorMessage, setErrorMessage] = React.useState();
    const [providerErrorMessage, setProviderErrorMessage] = React.useState();
    const [categoryErrorMessage, setCategoryErrorMessage] = React.useState();
    const [scanPeriodErrorMessage, setScanPeriodErrorMessage] = React.useState();
    const [channelErrorMessage, setChannelErrorMessage] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState();
    const [selectedChannels, setSelectedChannels] = React.useState([]);
    const [imageUrl, setImageUrl] = React.useState();
    const [available, setAvailable] = React.useState(true)
    const [sellerRequired, setSellerRequired] = React.useState(true)
    const [loading, setLoading] = React.useState(false)
    const [categories, setCategories] = React.useState([])
//     React.useEffect(() => {
//         if (props.categories) {
// setCategories(props.categories)
//         }
//     }, [props.categories])
// const getCategories = () => {
//     setLoading(true)
//     Axios.get(`/categories`).then(res => {
//         setCategories(res.data.categories)
//         setLoading(false)
//     })
//     .catch(error => {
//         setLoading(false)
//         // onResponse(error.response.data.message, 'error')
//     })
//   }
//   React.useEffect(() => {
//     getCategories()
//   },[])
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        reset()
        setFile()
        setSelectedChannels([])
        setImageUrl()
        setProviderErrorMessage()
        setCategoryErrorMessage()
        setScanPeriodErrorMessage()
        setChannelErrorMessage()
    };
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Please enter Title'),
        url: Yup.string()
        .required('Please enter URL').url('Enter correct url!'),
        // .matches(
        //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        //     'Enter correct url!'
        // ),
        image: Yup.string(),
        price: Yup.string(),
        targetPrice: Yup.string().required('Please enter target price'),
        sellerName: Yup.string(),
        provider: Yup.string(),
        category: Yup.string(),
        scanPeriod: Yup.string(),
        channel: Yup.string(),
    });
    const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm({
        resolver: yupResolver(validationSchema),
    });
    React.useEffect(() => {
        if (file) {
            setImageUrl(URL.createObjectURL(file));
        } else {
            setImageUrl()
        }
    }, [file]);
    const onSubmit = (data) => {
        let errors = 0
        if (!data.provider) {
            setProviderErrorMessage('field required')
            errors += 1
        }
        if (!data.category) {
            setCategoryErrorMessage('field required')
            errors += 1
        }
        if (!data.scanPeriod) {
            setScanPeriodErrorMessage('field required')
            errors += 1
        }
        if (!selectedChannels.length) {
            setChannelErrorMessage('field required')
            errors += 1
        }
        if (!errors) {
            let dataObj = createMedicalReportData(data)
            setLoading(true)
            Axios.post(`products`, dataObj)
            .then(res => {
                setLoading(false)
                props.onAdded(res.data.message, 'success')
                handleClose()
            })
            .catch(error => {
                setLoading(false)
                props.onAdded(error.response.data.message, 'error')
            })
        }
        
    }
    const createMedicalReportData = (data) => {
        let productData = new FormData()
        let providerId = data.provider && props.providers.filter(provider => provider.name === data.provider)[0].id
        let categoryId = data.category && props.categories.filter(category => category.name === data.category)[0].id
        let scanPeriodId = data.scanPeriod && props.scanPeriods.filter(scanPeriod => scanPeriod.name === data.scanPeriod)[0].id
        let selectedChannelsIds =  []
        selectedChannels.length ? selectedChannelsIds = selectedChannels.map(item => {return item.id}) : selectedChannelsIds = ''
        data.title ? productData.append('title', data.title) : ''
        data.url ? productData.append('url', data.url) : ''
        data.price ? productData.append('price', data.price) : ''
        data.targetPrice ? productData.append('target_price', data.targetPrice) : ''
        data.sellerName ? productData.append('seller_name', data.sellerName) : ''
        providerId ? productData.append('provider_id', providerId) : ''
        categoryId ? productData.append('category_id', categoryId) : ''
        scanPeriodId ? productData.append('scan_period_id', scanPeriodId) : ''
        // productData.append('is_available', available)
        // productData.append('is_seller_required', sellerRequired)
        sellerRequired ? productData.append('is_seller_required', 1) : productData.append('is_seller_required', 0)
        // selectedChannelsIds.length ? productData.append('notification_channel_ids', JSON.stringify(selectedChannelsIds)) : "";
        for (var i = 0; i < selectedChannelsIds.length; i++) {
            productData.append(`notification_channel_ids[${i}]`, selectedChannelsIds[i]);
        }
        // data.image ? productData.append('image', data.image) : ''
        return productData
    }

    return(
        <section className="">
            <Button variant="contained" onClick={handleOpen}>{<FormattedMessage id="app.labels.newProduct"/>}</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style} className="product-form">
                    <h3 className="text-center text-xl font-bold mb-2">{<FormattedMessage id="app.labels.newProduct" />}</h3>
                    <Box className="inputs-container">
                        <Box className="input-box">
                            <TextField 
                                label={<FormattedMessage id="app.labels.title"/>}
                                id="title"
                                name="title"
                                {...register('title')}
                                error={errors.title ? true : false}
                                variant="outlined" 
                                color="secondary"
                            />
                            <Typography variant="inherit" color="error">
                                {errors.title?.message}
                            </Typography>
                        </Box>
                        <Box className="input-box">
                            <TextField 
                             label={<FormattedMessage id="app.labels.url"/>}
                                id="url"
                                name="url"
                                {...register('url')}
                                error={errors.url ? true : false}
                                variant="outlined" 
                                color="secondary"
                            />
                            <Typography variant="inherit" color="error">
                                {errors.url?.message}
                            </Typography>
                        </Box>
                        {false && 
                        <Box className="input-box">
                            <TextField 
                                label={<FormattedMessage id="app.labels.price"/>}
                                id="price"
                                name="price"
                                {...register('price')}
                                error={errors.price ? true : false}
                                variant="outlined" 
                                color="secondary"
                                type="number"
                            />
                            <Typography variant="inherit" color="error">
                                {errors.price?.message}
                            </Typography>
                        </Box>}
                        <Box className="input-box">
                            <TextField 
                                label={<FormattedMessage id="app.labels.targetPrice"/>}
                                id="targetPrice"
                                name="targetPrice"
                                {...register('targetPrice')}
                                error={errors.targetPrice ? true : false}
                                variant="outlined" 
                                color="secondary"
                                type="number"
                            />
                            <Typography variant="inherit" color="error">
                                {errors.targetPrice?.message}
                            </Typography>
                        </Box>
                        <Box className="input-box">
                            <TextField 
                             label={<FormattedMessage id="app.labels.sellerName"/>}
                                id="sellerName"
                                name="sellerName"
                                {...register('sellerName')}
                                error={errors.sellerName ? true : false}
                                variant="outlined" 
                                color="secondary"
                            />
                            <Typography variant="inherit" color="error">
                                {errors.sellerName?.message}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="inputs-container">
                        <Box className="input-box">
                            <Autocomplete
                                className="uploader-autocomplete"
                                disablePortal {...register('scanPeriod')}
                                options={props.scanPeriods}
                                openOnFocus={true}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} {...register('scanPeriod')} 
                                label={<FormattedMessage id="app.labels.scanPeriods"/>}/>}
                            />
                            <Typography variant="inherit" color="error">
                                {scanPeriodErrorMessage}
                            </Typography>
                        </Box>
                        <Box className="input-box">
                            <Autocomplete
                                className="uploader-autocomplete"
                                disablePortal {...register('provider')}
                                options={props.providers}
                                openOnFocus={true}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} {...register('provider')} 
                                label={<FormattedMessage id="app.labels.provider"/>}
                                />}
                            />
                            <Typography variant="inherit" color="error">
                                {providerErrorMessage}
                            </Typography>
                        </Box>
                        <Box className="input-box">
                            <Autocomplete
                                className="uploader-autocomplete"
                                disablePortal {...register('category')}
                                options={props.categories}
                                openOnFocus={true}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} {...register('category')} 
                                label={<FormattedMessage id="app.labels.categories"/>}
                                />}
                            />
                            <Typography variant="inherit" color="error">
                                {categoryErrorMessage}
                            </Typography>
                        </Box>
                        <Box className="input-box">
                            <Autocomplete
                                value={selectedChannels}
                                onChange={(event, newValue) => {
                                    let idsArr = []
                                    newValue.map(item => {
                                        idsArr.push(item)
                                    })
                                    setSelectedChannels(idsArr);
                                }}
                                multiple
                                className="uploader-autocomplete"
                                disableCloseOnSelect
                                options={props.notificationChannels}
                                openOnFocus={true}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: 300 }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                        {option.name}
                                    </li>
                                )}
                                renderInput={(params) => <TextField {...params} {...register('channel')} 
                                label={<FormattedMessage id="app.labels.notificationChannels"/>}/>}
                            />
                            <Typography variant="inherit" color="error">
                                {channelErrorMessage}
                            </Typography>
                        </Box>
                    </Box>
                    <FormGroup>
                        {/* <FormControlLabel control={<Switch checked={available} onChange={(e) => setAvailable(e.target.checked)} />} label={<FormattedMessage id="app.labels.available"/>} /> */}
                        <FormControlLabel control={<Switch checked={sellerRequired} onChange={(e) => setSellerRequired(e.target.checked)} />} label={<FormattedMessage id="app.labels.sellerRequired"/>} />
                    </FormGroup>
                    {/* <Box className="inputs-container">
                        <Box className="input-box">
                            <input
                                accept="image/*"
                                type="file"
                                id={`select-product-image`}
                                style={{ display: 'none' }}
                                onChange={e => setFile(e.target.files[0])}
                            />
                            <div className='flex justify-between items-center'>
                                <div><FormattedMessage id="app.labels.productImage"/></div>
                            </div>
                            <label htmlFor={`select-product-image`} className=" block" style={{ width: '100%', height: '200px' }}>
                                <div style={{ width: '100%', height: '200px' }} className={`relative flex cursor-pointer ${imageUrl ? 'border' : 'border border-dashed'}`}>
                                    {imageUrl ? <img src={imageUrl} alt='product' className='w-full h-full object-contain'/>
                                    :<span className='m-auto text-5xl'>+</span>}
                                </div>
                            </label>
                        </Box>
                    </Box> */}
                    <div className="flex">   
                        <Button  className="ml-auto" onClick={handleClose}>{<FormattedMessage id="app.buttons.cancel"/>}</Button>
                        <Button disabled={loading} onClick={handleSubmit(onSubmit)}>{<FormattedMessage id="app.buttons.save"/>}</Button>
                    </div>
                </Box>
            </Modal>
            {successMessage && (<CustomSnackBar open={true} message={`${successMessage}`} severity="success" />)}
            {errorMessage && ( <CustomSnackBar open={true} message={`${errorMessage}`} severity="error" />)}
        </section>
    )
}