import { useState } from 'react';

export default function useUser() {
    const getUser = () => {
      const user = JSON.parse(localStorage.getItem('user'))  || ''
      return user
    };
    const [user, setUser] = useState(getUser());
    const saveUser = userData => {
      localStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);
    };
    return {
      setUser: saveUser,
      user
    }
}
