import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import Arabic from './lang/ar.json';
import English from './lang/en.json';

export const Context = React.createContext();

if(localStorage.getItem("locale")){
  // don't do any thing
} else {
  localStorage.setItem('locale', JSON.stringify('en'))
}

const local = JSON.parse(localStorage.getItem('locale')) || 'en';
let lang;
if (local === 'ar') {
    lang = Arabic;
    document.body.setAttribute("dir", "ltr");
}else {
    lang = English;
    document.body.setAttribute("dir", "ltr");
}

const LocaleWrapper = (props) => {
    const [locale, setLocale] = useState(local);

    const [messages, setMessages] = useState(lang);

    function selectLanguage(e) {
        // console.log(e)
        const newLocale = e
        // const newLocale = e.target.value;
        // console.log(e.target.value)
        setLocale(newLocale);
        if (newLocale === 'ar') {
            setMessages(Arabic);
            localStorage.setItem('locale', JSON.stringify('ar'));
            document.body.setAttribute("dir", "ltr");
        } else {
            setMessages(English);
            localStorage.setItem('locale', JSON.stringify('en'));
            document.body.setAttribute("dir", "ltr");
        }
     
        setTimeout(() => { window.location.reload() }, 100)
    }

    return (
        <Context.Provider value = {{locale, selectLanguage}}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>

    );
}


export default LocaleWrapper;