import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: [],
  reducers: {
    getAllUsersAction:(state,action)=>{
    //console.log("from get all user action ",action.payload)
      return state=action.payload
    },
    addUserAction: (state, action) => {
      //console.log("action payload from add user",action.payload)
      state.push(action.payload)
    },
    editUserAction:(state,action)=>{
      let index = state.findIndex((user) => user.id === action.payload.id);
      //console.log("from edit action index ele ",index);
      //console.log("from edit action state[index] ",state[index],"paylod",action.payload);
       state[index] = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { addUserAction ,getAllUsersAction,editUserAction} = userSlice.actions

export default userSlice.reducer