
import * as React from 'react';
import ProductsTable from "../../components/products/ProductsTable";

export default function Products() {
    return(
        <section>
            <ProductsTable />
        </section>
    )
}