import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './app/store';
import { baseUrl } from './baseUrl';
import LocaleWrapper from './components/Locale/LocaleWrapper';
import './index.scss';
const token = JSON.parse(localStorage.getItem('token'))
const locale = JSON.parse(localStorage.getItem('locale'))
const httpLink = new HttpLink({
  uri: `${baseUrl}graphql`,
  credentials: 'same-origin',
        headers: {
            "Accept": "application/json",
            "authorization": token ? `Bearer ${token}` : "",
            "X-Locale": locale ? locale : "en"
        }
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            if(message === 'Unauthenticated.' || message === 'Unauthenticated') {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                window.location.reload()
            }
        }
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache()
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <LocaleWrapper>
                <App />
            </LocaleWrapper>
        </Provider>
    </ApolloProvider>
, document.getElementById('root'));
