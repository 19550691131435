import React from 'react';
import './AuthWrapper.scss';

export default function AuthWrapper(props) {
  return(
    <section className="auth-page-wrapper" >
      <div className="auth-page-container">
        <div className="auth-form-wrapper">
            {props.children}
        </div>
      </div>
    </section>
  )
}