import { createSlice } from '@reduxjs/toolkit'

export const doctorSlice = createSlice({
  name: 'doctor',
  initialState: [],
  reducers: {
    getAllDoctorsAction:(state,action)=>{
    //console.log("from get all user action ",action.payload)
      return state=action.payload
    },
    addDoctorAction: (state, action) => {
      //console.log("action payload from add user",action.payload)
      state.push(action.payload)
    },
    editDoctorAction:(state,action)=>{
      let index = state.findIndex((user) => user.id === action.payload.id);
      //console.log("from edit action index ele ",index);
      //console.log("from edit action state[index] ",state[index],"paylod",action.payload);
       state[index] = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { addDoctorAction ,getAllDoctorsAction,editDoctorAction} = doctorSlice.actions

export default doctorSlice.reducer