import { configureStore } from '@reduxjs/toolkit'
import patientIdReducer from './slices/patientIdSlice'
import patientTabReducer from './slices/patientTabSlice'
import userReducer from './slices/userSlice'
import clinicReducer from './slices/clinicSlice'
import doctorReducer from './slices/doctorSlice'
import procedureReducer from './slices/procedureSlice'
import serviceReducer from './slices/serviceSlice'
import cashPaymentReducer from './slices/cashPaymentSlice'
import billSliceReducer from './slices/billSlice'
export default configureStore({
  reducer: {
    user: userReducer,
    patientId: patientIdReducer,
    patientTab: patientTabReducer,
    clinic:clinicReducer,
    doctor:doctorReducer,
    procedure:procedureReducer,
    service:serviceReducer,
    cashPayments:cashPaymentReducer,
    bills:billSliceReducer
  }
})