import Box from '@mui/material/Box';
// import loader from '../../assets/images/loader.gif';
// import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage } from 'react-intl';


export default function Loader(props) {

    return(
        <Box className="justify-center py-20 absolute" style={{ left: '45%' }} sx={{ display: 'flex' }}>
            <div className="text-center">  
            <p className="mb-3 capitalize">{props.message ? props.message : <FormattedMessage id="app.loadingMessage.loading"/>}</p>
            {/* <LinearProgress /> */}
            <CircularProgress />
            {/* <div className='flex w-full h-full'>
                <img src={loader} alt='loader' className='m-auto w-44 h-44 object-contain' />
            </div> */}
            </div>
        </Box>
    );
}