import { yupResolver } from '@hookform/resolvers/yup';
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
// import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
// import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import "react-phone-input-2/lib/material.css";
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// import logo from '../../assets/images/logo.png';
import Axios from '../../Axios';
import useToken from '../../useToken';
import useUser from '../../useUser';
import { UseFormatMessage } from "../helper/HelperMethods";
import CustomSnackBar from '../ui/CustomSnackBar';
import CustomTextField from '../ui/CustomTextField';
import './AuthForm.scss';

function Login() { 
    const [errorMessage, setErrorMessage] = useState()
    const [clinicDomainError, setClinicDomainError] = useState()
    const [userNameError, setUserNameError] = useState()
    const [passwordError, setPasswordError] = useState()
    const [emailError, setEmailError] = useState();
    const [phoneError, setPhoneError] = useState();
    const [phone, setPhone] = useState();
    const [phoneErrorMessage, setPhoneErrorMessage] = useState();
    const [phoneValue, setPhoneValue] = useState('20');
    const [phoneKeyElement, setPhoneKeyElement] = useState(
        document.getElementsByClassName(" react-tel-input")[0]
    );
    const [loginWithEmail, setLoginWithEmail] = useState(true)
    const { setToken } = useToken();
    const { setUser } = useUser();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    
    
  useEffect(() => {
    if (document.getElementsByClassName(" react-tel-input")[0]) {
      setPhoneKeyElement(
        document.getElementsByClassName(" react-tel-input")[0]
      );
    }
  }, [phoneKeyElement, document.getElementsByClassName(" react-tel-input")[0]]);
    const validationSchema = Yup.object().shape({
        password: Yup.string()
        .required(UseFormatMessage("app.ErrorMessage.passowrdIsRequired"))
        .min(4, UseFormatMessage("app.ErrorMessage.passwordFormat1")),
        phoneKey: Yup.string(),
        email: Yup.string()
        .required(UseFormatMessage("app.ErrorMessage.emailIsRequired"))
        .email(UseFormatMessage("app.ErrorMessage.emailIsInvalid")),
  });
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    
    const onSubmit = data => {
        setErrorMessage()
        let loginObj = {}
        loginObj['email'] = data.email
        loginObj['password'] = data.password
        setLoading(true)
        Axios.post(`login`, loginObj)
        .then(res => {
          setLoading(false)
          setToken(res.data.token)
          setUser(res.data.user)
          navigate(state ? state.path : "/products");
          window.location.reload()
        })
        .catch(error => {
          setLoading(false)
          setErrorMessage(error.response.data.message)
        })
    };
    
// controll show pssword
const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  
  
    return (
        <section className="auth-wrapper relative">
            {/* <figure className="logo">
                <img src={logo} alt="logo"></img>
            </figure> */}
            <div className="logo">
              Logo
            </div>
            <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
                <Box className="input-box">
                    <CustomTextField
                    label={<FormattedMessage id= "app.labels.Email"/>}
                        id="email"
                        name="email"
                        {...register("email")}
                        error={errors.email ? true : false}
                        variant="outlined"
                        color="secondary"
                        InputProps={{
                        startAdornment: (
                            <InputAdornment
                            className="start-clinic-adornment"
                            position="start"
                            >
                            <EmailOutlinedIcon color="primary" />
                            </InputAdornment>
                        ),
                        }}
                    />
                    <Typography variant="inherit" color="error">
                        {errors.email?.message}
                    </Typography>
                </Box>
                
                <Box className="input-box password-box">
                    <CustomTextField
                    label={<FormattedMessage id="app.labels.Password"/>}
                      //  label="Password"
                        id="password"
                        name="password"
                        {...register('password')}
                        error={errors.password ? true : false}
                        variant="outlined"
                        type={values.showPassword ? 'text' : 'password'}
                        color="secondary"
                        InputProps={{ 
                        endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="end-clinic-adornment justify-end"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                            position="end"
                                        >
                                            {values.showPassword ? <VisibilityOff className="fill-current text-secondary"/> : <Visibility className="fill-current text-secondary"/>}
                                        </IconButton>
                                     </InputAdornment>,
                        startAdornment: <InputAdornment className="start-clinic-adornment" position="start"><LockOutlinedIcon color="primary" /></InputAdornment>}}
                    />
                    <Typography variant="inherit" color="error">
                        {errors.password?.message}
                    </Typography>
                </Box>
                
                <div className="button-wrapper pt-3">
                    <Button className="button" variant="contained" disabled={loading} type="submit" >{<FormattedMessage id="app.buttons.Sign in"/>}</Button>
                </div>
            </form>
            { errorMessage && <CustomSnackBar open={true} message={`${errorMessage}`} severity="error"/> }
        </section>
    )
}

export default Login;