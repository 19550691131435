/* eslint-disable no-unused-expressions */
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Axios from '../../Axios';
// import { Add_Procedure_Category } from '../../../graphQl/medicalProcedures/medicalProceduresQuery&mutation';
// import "../../patient/PatientsWrapper.scss";
import NotificationChannel from "./NotificationChannel.js";

export default function NotificationsChannelTable(props) {
  const [notificationChannels, setNotificationChannels] = React.useState(props.notificationChannels)
  const [name, setName] = React.useState()
  const [code, setCode] = React.useState()
  const [showAdd, setShowAdd] = React.useState(false)
  // React.useEffect(() => {
  //   if (props.categories) {
  //     setCategories(props.categories)
  //   }
  // },[props.categories])
  const onChanged = (data, type) => {
    props.onChanged(data, type);
  };
  const addNotificationChannel = () => {
    if (name && code) {
        let notificationChannelData = new FormData()
        name ? notificationChannelData.append('name', name) : ''
        code ? notificationChannelData.append('chat_id', code) : ''
        Axios.post(`notification-channels`, notificationChannelData)
        .then(res => {
            onChanged(res.data.message, 'success')
        })
        .catch(error => {
            onChanged(error.response.data.message, 'error')
        })
    }
  }
  return (
    <TableContainer className="patients-table-container">
      <Table
        stickyHeader
        sx={{ minWidth: 500 }}
        size="small"
        className="patients-table"
      >
        <TableHead className="patients-table-head relative h-10">
          <TableRow className="patients-table-head-row">
            <TableCell>{<FormattedMessage id="app.labels.name"/>}</TableCell>
            <TableCell>{<FormattedMessage id="app.labels.code"/>}</TableCell>
            
            <TableCell align="right">
              <Button variant="contained" className="mx-2 absolute right-20 bottom-0 whitespace-nowrap" onClick={() => setShowAdd(true)}>{<FormattedMessage id="app.labels.newNotificationChannel"/>}</Button>
              {<FormattedMessage id="app.labels.patientsActions"/>}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="patients-table-body">
         {showAdd ?  <TableRow
              className="patients-table-body-row"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell scope="row">
                <div className="procedures-category">
                  <Box className="input-box">
                      <TextField
                          value={name}
                          id="name"
                          name="name"
                          variant="outlined"
                          color="secondary"
                          onChange={(e) => setName(e.target.value)}
                          className="title-field"
                      />
                  </Box>
                </div>
              </TableCell>
              <TableCell scope="row">
                <div className="procedures-category">
                  <Box className="input-box">
                      <TextField
                          value={code}
                          id="code"
                          name="code"
                          variant="outlined"
                          color="secondary"
                          onChange={(e) => setCode(e.target.value)}
                          className="title-field"
                      />
                  </Box>
                </div>
              </TableCell>
                  
              <TableCell align="right">
                <div className="flex items-center justify-end gap-2">
                  <Button variant="contained" disabled={!name || !code} onClick={addNotificationChannel}>add</Button>
                  <Button variant="outlined" onClick={() => {
                    setShowAdd(false)
                    setName()
                    setCode()
                  }}>cancel</Button>
                </div>
              </TableCell>
              
            </TableRow> : ''}
          {notificationChannels.length? notificationChannels.map((notificationChannel) => (
            <>
            <NotificationChannel key={notificationChannel.id} notificationChannel={notificationChannel} onChanged={onChanged} /> 
            </>
          )) : 
          ''}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
