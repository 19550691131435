import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Axios from '../../Axios';
import Deleter from '../helper/Deleter';
import Loader from '../helper/Loader';
import CustomSnackBar from '../ui/CustomSnackBar';
import EditProduct from './EditProduct';
import NewProduct from './NewProduct';
import './Products.scss';

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const columns = [
  { id: 'id', label: 'id'},
  // { id: 'image', label: 'image',},
  { id: 'title', label: 'title', width: '300px'},
  { id: 'price', label: 'price',},
  { id: 'target_price', label: 'targetPrice',},
  { id: 'seller_name', label: 'sellerName',},
  { id: 'exists_seller_name', label: 'existsSellerName',},
  { id: 'is_available', label: 'available', },
  { id: 'provider', label: 'provider', },
  { id: 'scan_period', label: 'scanPeriods', },
  { id: 'actions', label: 'actions', },
];

export default function ProductsTable() {
  const [successMessage, setSuccessMessage] = React.useState()
  const [errorMessage, setErrorMessage] = React.useState()
  const [products, setProducts] = React.useState([]);
  const [providers, setProviders] = React.useState([]);
  const [scanPeriods, setScanPeriods] = React.useState([]);
  const [notificationChannels, setNotificationChannels] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [refetch, setRefetch] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [searchAttr, setSearchAttr] = React.useState('all');
  const [searchText, setSearchText] = React.useState('');
  const [searchInputData, setSearchInputData] = React.useState()
  const [selectedProvider, setSelectedProvider] = React.useState('all');
  const [selectedScanPeriod, setSelectedScanPeriod] = React.useState('all');
  const [selectedNotificationChannel, setSelectedNotificationChannel] = React.useState('all');
  const [selectedCategory, setSelectedCategory] = React.useState('all');
  const [selectedAvailable, setSelectedAvailable] = React.useState('all');
  const [selectedSellerRequired, setSelectedSellerRequired] = React.useState('all');
  const [selectedSort, setSelectedSort] = React.useState();
  const [queriesArr, setQueriesArr] = React.useState(['', '', '', '', '', '', selectedSort, '']);
  const [filterSentience, setFilterSentience] = React.useState('');
  const [hideFilter, setHideFilter] = React.useState(false);
  const [showPagination, setShowPagination] = React.useState(true);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([15, 25, 50, 100, { label: 'All', value: total }]);
  
  const getProviders = () => {
    setLoading(true)
    Axios.get(`/providers`).then(res => {
        setProviders(res.data.providers)
        setLoading(false)
    })
    .catch(error => {
        setLoading(false)
        onResponse(error.response.data.message, 'error')
    })
  }
  const getScanPeriods = () => {
    setLoading(true)
    Axios.get(`/scan-periods`).then(res => {
        setScanPeriods(res.data.scan_periods)
        setLoading(false)
    })
    .catch(error => {
        setLoading(false)
        onResponse(error.response.data.message, 'error')
    })
  }
  const getNotificationChannels = () => {
    setLoading(true)
    Axios.get(`/notification-channels`).then(res => {
        setNotificationChannels(res.data.notification_channels)
        setLoading(false)
    })
    .catch(error => {
        setLoading(false)
        onResponse(error.response.data.message, 'error')
    })
  }
  const getCategories = () => {
    setLoading(true)
    Axios.get(`/categories`).then(res => {
        setCategories(res.data.categories)
        setLoading(false)
    })
    .catch(error => {
        setLoading(false)
        onResponse(error.response.data.message, 'error')
    })
  }
  const getProducts = () => {
    setLoading(true)
    Axios.get(`/products?page=${page + 1}&per_page=${rowsPerPage}${filterSentience}`).then(res => {
        setProducts(res.data.products.data)
        setTotal(res.data.products.total)
        setLoading(false)
    })
    .catch(error => {
        setLoading(false)
        onResponse(error.response.data.message, 'error')
    })
  }
    
    React.useEffect(() => {
      console.log(rowsPerPage)
        getProducts()
    }, [page, rowsPerPage, refetch])
    
    React.useEffect(() => {
        if (rowsPerPage != 15 || 25 || 50 || 100 || total) {
          setRowsPerPage(15)
        }
        // setShowPagination(false)
        // setTimeout(() => {
        //   setShowPagination(true)
        // }, 10);
    }, [total])
    
    React.useEffect(() => {
        getCategories()
        getProviders()
        getScanPeriods()
        getNotificationChannels()
    }, [])
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  
  const onResponse = (data, type) => {
    setSuccessMessage()
    setErrorMessage()
    setTimeout(() => {
      if (type === 'success') {
        setSuccessMessage(data)
      }
      if (type === 'error') {
        setErrorMessage(data)
      }
    }, 10);
    setRefetch(refetch + 1)
  }

  const handleTypeChange = (event) => {
    setSearchAttr(event.target.value);
  };
  const handleTextChange = (event) => {
    console.log(event.target.value)
    setSearchText(event.target.value);
  };
  // const passSearchData = () => {
  //   setSearchInputData(`&${searchAttr}=${searchText}`)
  // }
  const clearSearch = () => {
    setSearchAttr('all')
    setSearchText('')
    setSearchInputData()
    setSelectedProvider('all')
    setSelectedScanPeriod('all')
    setSelectedNotificationChannel('all')
    setSelectedCategory('all')
    setSelectedAvailable('all')
    setSelectedSellerRequired('all')
    setSelectedSort()
    setQueriesArr(['', '', '', '', '', '', '', ''])
    setFilterSentience('')
    setHideFilter(true)
    setTimeout(() => {
      setHideFilter(false)
    }, 10);
    setRefetch(refetch + 1)
  }
  
  React.useEffect(() => {
    let arr = [...queriesArr]
    console.log(searchText)
    searchText ? arr.splice(0, 1, `&${searchAttr}=${searchText}`) : arr.splice(0, 1, '') 
    selectedProvider && selectedProvider !== 'all' ? arr.splice(1, 1, `&provider=${selectedProvider.id}`) : arr.splice(1, 1, '') 
    selectedScanPeriod && selectedScanPeriod !== 'all' ? arr.splice(2, 1, `&scan_period=${selectedScanPeriod.id}`) : arr.splice(2, 1, '') 
    selectedNotificationChannel && selectedNotificationChannel !== 'all' ? arr.splice(3, 1, `&notification_channel=${selectedNotificationChannel.id}`) : arr.splice(3, 1, '') 
    selectedAvailable !== 'all' ? arr.splice(4, 1, `&available=${selectedAvailable}`) : arr.splice(4, 1, '') 
    selectedSellerRequired !== 'all' ? arr.splice(5, 1, `&seller_required=${selectedSellerRequired}`) : arr.splice(5, 1, '') 
    selectedSort ? arr.splice(6, 1, `&sort=${selectedSort}`) : arr.splice(6, 1, '') 
    selectedCategory && selectedCategory !== 'all' ? arr.splice(3, 1, `&category=${selectedCategory.id}`) : arr.splice(3, 1, '') 
    setQueriesArr(arr)
  }, [searchAttr, searchText,searchInputData, selectedProvider, selectedScanPeriod, selectedNotificationChannel, selectedAvailable, selectedSellerRequired, selectedSort, selectedCategory])
  React.useEffect(() => {
    console.log(queriesArr)
    let querySentience = queriesArr.toString().replaceAll(',', '')
    console.log(querySentience)
    setFilterSentience(querySentience)
  }, [queriesArr])
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <div className='p-2'>
        <div className='flex justify-between items-center mb-2'>
          <div className='invisible hidden lg:block'>
            <NewProduct providers={providers} scanPeriods={scanPeriods} notificationChannels={notificationChannels} categories={categories} onAdded={onResponse}/>
          </div>
          <div className='flex justify-center gap-2'>
            <Paper
              className="patients-search-bar-container"
            >
              <FormattedMessage id="app.input.Search">
                { placeholder =>
                  <InputBase
                    className="patients-search-bar-input"
                    // onKeyPress={(e) => {
                    //     if(e.key === 'Enter'){
                    //         passSearchData()
                    //     }
                    // }}
                    placeholder={placeholder}
                    value={searchText}
                    onChange={handleTextChange}
                    
                  />
                }
                </FormattedMessage>
              {searchText && <span className="font-bold text-primary cursor-pointer" onClick={clearSearch}>x</span>}
              <Divider sx={{ height: 28, m: 0.5 }} className="bg-primary bg-opacity-25" orientation="vertical" />
              <Select
                  className="patients-search-bar-select"
                  value={searchAttr}
                  onChange={handleTypeChange}
                  // onClose={(e) => {
                  //   if(e.key === 'Enter'){
                  //         passSearchData()
                  //     }
                  // }}
                >
                  <MenuItem value='all'>{<FormattedMessage id="app.labels.all"/>}</MenuItem>
                  <MenuItem value='title'>{<FormattedMessage id="app.labels.title"/>}</MenuItem>
                  <MenuItem value='url'>{<FormattedMessage id="app.labels.url"/>}</MenuItem>
                  <MenuItem value='price_range'>{<FormattedMessage id="app.labels.price"/>}</MenuItem>
                  <MenuItem value='target_price_range'>{<FormattedMessage id="app.labels.targetPrice"/>}</MenuItem>
                  <MenuItem value='seller_name'>{<FormattedMessage id="app.labels.sellerName"/>}</MenuItem>
              </Select>
            </Paper>
            <Button className="patients-search-bar-button" onClick={() => setRefetch(refetch + 1)} variant="contained" aria-label="search">
              <SearchIcon />
            </Button>
            {filterSentience && <Button className="patients-search-bar-button" onClick={clearSearch} variant="contained" aria-label="search">
              X
            </Button>}
          </div>
          <NewProduct providers={providers} scanPeriods={scanPeriods} notificationChannels={notificationChannels} categories={categories} onAdded={onResponse}/>
        </div>
        <div className='flex items-end justify-start xl:justify-center gap-1 h-12 overflow-auto'>
          {!hideFilter && <FormControl sx={{ minWidth: 200}}>
            <InputLabel id="demo-multiple-name-label-provider" className='filter-select-label'><FormattedMessage id={`app.labels.provider`}/></InputLabel>
            <Select
              labelId="demo-multiple-name-label-provider"
              id="demo-multiple-name-provider"
              value={selectedProvider}
              onChange={(event) => setSelectedProvider(event.target.value)}
              input={<OutlinedInput label={<FormattedMessage id={`app.labels.provider`}/>} />}
              size="small"
              MenuProps={{ classes: {paper : 'filter-select-drop-down'} }}
            >
              <MenuItem value='all'>{<FormattedMessage id="app.labels.all"/>}</MenuItem>
              {providers.map((provider) => (
                <MenuItem
                  key={provider}
                  value={provider}
                >
                  {provider.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
          {!hideFilter && <FormControl sx={{ minWidth: 150}}>
            <InputLabel id="demo-multiple-name-label-scanPeriod" className='filter-select-label'><FormattedMessage id={`app.labels.scanPeriods`}/></InputLabel>
            <Select
              labelId="demo-multiple-name-label-scanPeriod"
              id="demo-multiple-name-scanPeriod"
              value={selectedScanPeriod}
              onChange={(event) => setSelectedScanPeriod(event.target.value)}
              input={<OutlinedInput label={<FormattedMessage id={`app.labels.scanPeriods`}/>} />}
              size="small"
              MenuProps={{ classes: {paper : 'filter-select-drop-down'} }}
            >
              <MenuItem value='all'>{<FormattedMessage id="app.labels.all"/>}</MenuItem>
              {scanPeriods.map((scanPeriod) => (
                <MenuItem
                  key={scanPeriod}
                  value={scanPeriod}
                >
                  {scanPeriod.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
          {!hideFilter && <FormControl sx={{ minWidth: 288  }}>
            <InputLabel id="demo-multiple-name-label-notificationChannels" className='filter-select-label'><FormattedMessage id={`app.labels.notificationChannels`}/></InputLabel>
            <Select
              labelId="demo-multiple-name-label-notificationChannels"
              id="demo-multiple-name-notificationChannels"
              value={selectedNotificationChannel}
              onChange={(event) => setSelectedNotificationChannel(event.target.value)}
              input={<OutlinedInput label={<FormattedMessage id={`app.labels.notificationChannels`}/>} />}
              size="small"
              MenuProps={{ classes: {paper : 'filter-select-drop-down'} }}
            >
              <MenuItem value='all'>{<FormattedMessage id="app.labels.all"/>}</MenuItem>
              {notificationChannels.map((notificationChannel) => (
                <MenuItem
                  key={notificationChannel}
                  value={notificationChannel}
                >
                  {notificationChannel.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
          {!hideFilter && <FormControl sx={{ minWidth: 288  }}>
            <InputLabel id="demo-multiple-name-label-notificationChannels" className='filter-select-label capitalize'><FormattedMessage id={`app.labels.categories`}/></InputLabel>
            <Select
              labelId="demo-multiple-name-label-notificationChannels"
              id="demo-multiple-name-notificationChannels"
              value={selectedCategory}
              onChange={(event) => setSelectedCategory(event.target.value)}
              input={<OutlinedInput label={<FormattedMessage id={`app.labels.categories`}/>} />}
              size="small"
              MenuProps={{ classes: {paper : 'filter-select-drop-down'} }}
            >
              <MenuItem value='all'>{<FormattedMessage id="app.labels.all"/>}</MenuItem>
              {categories.map((category) => (
                <MenuItem
                  key={category}
                  value={category}
                >
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
          {!hideFilter && <FormControl sx={{ minWidth: 150}}>
            <InputLabel id="demo-multiple-name-label-notificationChannels" className='filter-select-label'><FormattedMessage id={`app.labels.available`}/></InputLabel>
            <Select
              labelId="demo-multiple-name-label-notificationChannels"
              id="demo-multiple-name-notificationChannels"
              value={selectedAvailable}
              onChange={(event) => setSelectedAvailable(event.target.value)}
              input={<OutlinedInput label={<FormattedMessage id={`app.labels.available`}/>} />}
              size="small"
              MenuProps={{ classes: {paper : 'filter-select-drop-down'} }}
              className="capitalize"
            >
              {['all', 'true', 'false'].map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  className="capitalize"
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
          {!hideFilter && <FormControl sx={{ minWidth: 150}}>
            <InputLabel id="demo-multiple-name-label-notificationChannels" className='filter-select-label'><FormattedMessage id={`app.labels.sellerRequired`}/></InputLabel>
            <Select
              labelId="demo-multiple-name-label-notificationChannels"
              id="demo-multiple-name-notificationChannels"
              value={selectedSellerRequired}
              onChange={(event) => setSelectedSellerRequired(event.target.value)}
              input={<OutlinedInput label={<FormattedMessage id={`app.labels.sellerRequired`}/>} />}
              size="small"
              MenuProps={{ classes: {paper : 'filter-select-drop-down'} }}
              className="capitalize"
            >
              {['all', 'true', 'false'].map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  className="capitalize"
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
          {!hideFilter && <FormControl sx={{ minWidth: 150}}>
            <InputLabel id="demo-multiple-name-label-notificationChannels" className='filter-select-label'><FormattedMessage id="app.labels.sort"/></InputLabel>
            <Select
              labelId="demo-multiple-name-label-notificationChannels"
              id="demo-multiple-name-notificationChannels"
              value={selectedSort}
              onChange={(event) => setSelectedSort(event.target.value)}
              input={<OutlinedInput label={<FormattedMessage id={`app.labels.sort`}/>} />}
              size="small"
              MenuProps={{ classes: {paper : 'filter-select-drop-down'} }}
              className="capitalize"
            >
                  <MenuItem value='latest' className="capitalize">{<FormattedMessage id="app.labels.latest"/>}</MenuItem>
                  <MenuItem value='oldest' className="capitalize">{<FormattedMessage id="app.labels.oldest"/>}</MenuItem>
                  <MenuItem value='price-desc' className="capitalize">{<FormattedMessage id="app.labels.priceHighToLow"/>}</MenuItem>
                  <MenuItem value='price-asc' className="capitalize">{<FormattedMessage id="app.labels.priceLowToHigh"/>}</MenuItem>
            </Select>
          </FormControl>}
        </div>
      </div>
      <TableContainer style={{ height: `calc(100vh - 233px)` }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, maxWidth: column.maxWidth || '300px', width: column.width}}
                >
                  <FormattedMessage id={`app.labels.${column.label}`}/>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? <Loader />
            : <TableBody>
             {products.map((product) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={product.id}>
                        <TableCell>{product.id ? product.id : <hr />}</TableCell>
                        {/* <TableCell>{product.image ? <img src={product.image} alt="product img" className='w-24 h-24 object-contain'/> : <hr />}</TableCell> */}
                        <TableCell>{product.title ? <a href={product.url} target="_blank" rel='noreferrer' className='text-blue-600'>{product.title}</a> : <hr />}</TableCell>
                        <TableCell>{product.price ? product.price : <hr />}</TableCell>
                        <TableCell>{product.target_price ? product.target_price : <hr />}</TableCell>
                        <TableCell>{product.seller_name ? product.seller_name : <hr />}</TableCell>
                        <TableCell>{product.exists_seller_name ? product.exists_seller_name : <hr />}</TableCell>
                        <TableCell><Switch checked={product.is_available} disabled/></TableCell>
                        <TableCell>{product.provider ? product.provider.name : <hr />}</TableCell>
                        <TableCell>{product.scan_period ? product.scan_period.name : <hr />}</TableCell>
                        <TableCell>
                            <EditProduct product={product}  providers={providers} scanPeriods={scanPeriods} notificationChannels={notificationChannels} categories={categories} onUpdated={onResponse}/>
                            <Deleter route="products" id={product.id} title='Delete Product' description={`are you sure you want delete ${product.title}`} onDeleted={onResponse}/>
                        </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 25, 50, 100, { label: 'All', value: total }]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      { successMessage && <CustomSnackBar open={true} message={`${successMessage}`} severity="success"/> }
      { errorMessage && <CustomSnackBar open={true} message={`${errorMessage}`} severity="error"/> }
    </Paper>
  );
}