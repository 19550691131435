import { ContactSupportOutlined } from '@mui/icons-material'
import { createSlice } from '@reduxjs/toolkit'

export const cashPaymentSlice = createSlice({
  name: 'cashPayment',
  initialState: {
    paymentArr:[]
  },
  reducers: {
    getAllCashPaymentsAction:(state,action)=>{
   // console.log("from get All cash action ",action.payload)
     state.paymentArr=[...action.payload]
    },
    addCashPaymentAction: (state, action) => {
  
     state.paymentArr.push(action.payload)
      //state.paymentArr=[...state.paymentArr,action.payload]
      //console.log("state after pus",state.paymentArr)
    },
    editCashPaymentAction:(state,action)=>{
      let index = state.findIndex((user) => user.id === action.payload.id);
      //console.log("from edit action index ele ",index);
      //console.log("from edit action state[index] ",state[index],"paylod",action.payload);
       state[index] = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { addCashPaymentAction ,getAllCashPaymentsAction,editCashPaymentAction} = cashPaymentSlice.actions

export default cashPaymentSlice.reducer