/* eslint-disable no-unused-expressions */
import { useIntl } from 'react-intl'

export const translation = (param, locale) => {
    let trans
    locale ? '' : locale = localStorage.getItem('locale').replaceAll('"', '')
    trans = param + locale.charAt(0).toUpperCase() + locale.slice(1)
    return trans
}

export const testPagePermission = (page) => {
    let user = JSON.parse(localStorage.getItem('user'))  || ''
    const result = user?.permissions?.some(permission => permission.key === page)
    return result
}

export const handlePhoneNumber = (number) => {
    let correctNumber = ''
    if (number.startsWith("002")) {
        correctNumber = number.replace('002','2')
    } else if (number.charAt(0) === '0') {
        correctNumber = `2${number}`
    } else if(number.charAt(0) === '2') {
        correctNumber = number
    }
    return correctNumber
}

export const UseFormatMessage = (messageId) => {
    return useIntl().formatMessage({ id: messageId })
}