import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// import '../../patient/Patients.scss';
import Axios from '../../Axios';
import Loader from '../../components/helper/Loader';
import NotificationsChannelTable from '../../components/notificationsChannel/NotificationsChannelTable';
import CustomSnackBar from '../../components/ui/CustomSnackBar';
import './NotificationsChannel.scss';
export default function NotificationsChannel() {
  const [successMessage, setSuccessMessage] = React.useState()
  const [errorMessage, setErrorMessage] = React.useState();
  const [notificationChannels, setNotificationChannels] = React.useState([])
  const [update, setUpdate] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  
  
  const getNotificationChannels = () => {
    setLoading(true)
    Axios.get(`/notification-channels`).then(res => {
        setNotificationChannels(res.data.notification_channels)
        setLoading(false)
    })
    .catch(error => {
        setLoading(false)
        onResponse(error.response.data.message, 'error')
    })
  }
  
  React.useEffect(() => {
      getNotificationChannels()
  }, [update])

  const onResponse = (data, type) => {
      setSuccessMessage()
      setErrorMessage()
      setTimeout(() => {
          if (type === 'success') {
              setSuccessMessage(data)
              setUpdate(update + 1)
          } else if(type === 'error') {
              setErrorMessage(data)
          }
      }, 10);
  }
  
  
  return (
    <section className={`flex h-full overflow-hidden`}>

      <section className={`procedures-categories-page-wrapper patients-page-wrapper w-full`} >
          <div className="relative">
            {
              loading ? <Loader message={<FormattedMessage id="app.loadingMessage.loading"/>} />
            : <NotificationsChannelTable  notificationChannels={notificationChannels} onChanged={onResponse}/> 
            }
          </div>
      </section>
      { successMessage && <CustomSnackBar open={true} message={`${successMessage}`} severity="success"/> }
      { errorMessage && <CustomSnackBar open={true} message={`${errorMessage}`} severity="error"/> }
    </section>
  );
}
