import { createSlice } from '@reduxjs/toolkit'

export const billSlice = createSlice({
  name: 'bill',
  initialState: {
    billsArr:[],
    updatedBillId: null
  },
  reducers: {
    getAllBillsAction:(state,action)=>{
      console.log("from get alfffffffffffffction ",action.payload)
      state.billsArr=[...action.payload]
    //console.log("from get all user action ",action.payload)
     // return state=action.payload
    },
    addBillAction: (state, action) => {
      //console.log("action payload from add user",action.payload)
      state.billsArr.push(action.payload)
    },
    editBillAction:(state,action)=>{
      let index = state.billsArr.findIndex((bill) => bill.id === action.payload.id);
      //console.log("from edit action index ele ",index);
      //console.log("from edit action state[index] ",state[index],"paylod",action.payload);
       state.billsArr[index] = action.payload;
       state.updatedBillId = action.payload.id
    }
  }
})

// Action creators are generated for each case reducer function
export const { addBillAction ,getAllBillsAction,editBillAction} = billSlice.actions

export default billSlice.reducer