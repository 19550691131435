import { useContext } from 'react';
import { Context } from "./LocaleWrapper";
export default function LocaleSwitcher() {
    const context = useContext(Context);
    // console.log(context.locale === 'en')
    return(
     <section className="flex item-center">
       {/* { context.locale === 'ar' ? 
        <div className="inline-block cursor-pointer" onClick={() => context.selectLanguage('en')} ><figure className="w-6"><img src={en} alt="" className="w-full h-full" /></figure></div> : 
        <div className="inline-block cursor-pointer" onClick={() => context.selectLanguage('ar')} ><figure className="w-6"><img src={ar} alt="" className="w-full h-full" /></figure></div> } */}
       { context.locale === 'ar' ? 
        <div className="inline-block cursor-pointer text-primary font-bold text-xl" onClick={() => context.selectLanguage('en')} >Ar</div> : 
        <div className="inline-block cursor-pointer text-primary font-bold text-xl" onClick={() => context.selectLanguage('ar')} >En</div> }
     </section> 
        // <select value = {context.locale} onChange={context.selectLanguage}>
        //   <option value= 'en'>English</option>
        //   <option value= 'ar'>Arabic</option>
        // </select>
    );
}